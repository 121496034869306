

const cookiePolicy = () => {

    let toggleUrl;


    const checkUrl = (url) => {
        if (url === "/cookie-policy.html") {
            toggleUrl = 0
        } else if (url === "/terms-of-use.html") {
            toggleUrl = 1
        } else {
            toggleUrl = 2
        }
    }

    checkUrl(window.location.pathname)

    $(`#switch-language-plan-${toggleUrl}`).change(function () {
        if (this.checked) {
            $(".banner-title").css("display", "block")
            $(".bannerEng-title").css("display", "none")
            $(".eng").css("opacity", "0.3")
            $(".tr").css("opacity", "1")


            $(".usecaseEng-detail").css("display", "none")
            $(".usecase-detail").css("display", "block")


        } else {
            $(".bannerEng-title").css("display", "block")
            $(".banner-title").css("display", "none")

            $(".eng").css("opacity", "1")
            $(".tr").css("opacity", "0.3")

            $(".usecaseEng-detail").css("display", "block")
            $(".usecase-detail").css("display", "none")


        }
    });
}

export { cookiePolicy }
