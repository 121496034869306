import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

function homeFaq() {
  $(".home-faq .faq-container .faq-item-container .faq-item .item-header").click(function () {
    $(this).parent().toggleClass("active")
    $(this).next(".item-body").slideToggle()
  });

  // scroll top button
  $(window).scroll(function () {
    var origin = window.location.href;

    if (origin === `${process.env.BASE_URL}` || origin === "https://loadmance.com/" || origin === `${process.env.BASE_URL}faq.html` || origin === "https://loadmance.com/faq.html") {

      const footer =  document.querySelector(".footer-home-container")
      const scrollTopButton =  document.querySelector(".buton-up")
       const footerPosition = footer.getBoundingClientRect();

      if ($(this).scrollTop() > 920) {
         $(".buton-up").show();
        // console.log("footerposition",footerPosition);
        // console.log("innerheight",window.innerHeight);
        if (footerPosition.top <= window.innerHeight) {
          scrollTopButton.style.bottom = `${window.innerHeight - footerPosition.top + 20}px`;
      } else {
           scrollTopButton.style.bottom = "20px";
      }
      } else if ($(this).scrollTop() < 920) {
        $(".buton-up").hide();
      }
    }
  });

  $(".buton-up span").click(function () {
    console.log("tıkladın");
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  })




  const executeCodes = () => {

    const cookieBox = document.querySelector(".wrapper2")
    const button = document.querySelector("#cookie-close-icon")
    $(".wrapper2").removeClass("show")


    //if cookie contains codinglab it will be returned and below of this code will not run
    if (document.cookie.includes("virgosol")) {
      cookieBox.classList.add("show");
      return
    };


    button.addEventListener("click", () => {
      cookieBox.classList.add("show");
      if (button.id == "cookie-close-icon") {
        //set cookies for 1 month. 60 = 1 min, 60 = 1 hours, 24 = 1 day, 30 = 30 days
        document.cookie = "cookieBy= virgosol; max-age=" + 60 * 60 * 24 * 1;
      }


    });

  };
  //executeCodes function will be called on webpage load.

  if(window.location.pathname === "/"){

    executeCodes()
  }
}

export { homeFaq }
