const contact = () => {
    $("#phone").intlTelInput({
        separateDialCode: false,
        nationalMode: false,  //alan kodu koymadan numara girişi izni
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
        initialCountry: "tr",

    })
    $("#phone").on("countrychange", function () {
        var countryCode = $(this).intlTelInput("getSelectedCountryData").dialCode;
        if (countryCode !== undefined) {
            $(this).val(`(+${countryCode }) `);
        }
    })

    setTimeout(() => {
        $(document).ready(function () {
            var initialCountry = $("#phone").intlTelInput("getSelectedCountryData").dialCode;
            $("#phone").val(`(+${initialCountry}) `);
        });
    }, 500)


    $("#phone").on("input", function (e) {
        let val = e.target.value;
        val = val.replace(/\s/gm, '')
        //only integer
        if (!/^[0-9+\s()]*$/.test(val)) {
            val = val.replace(/[^0-9+\s()]/g, '');
            e.target.value = val;
            return;
        }

        if (val.length > 20) {
            val = val.slice(0, 20);
            e.target.value = val;
        }
    })



    $("#emailform").submit(function (event) {
        event.preventDefault();
        let result = validateForm()
        if (result) {
            grecaptcha.ready(function () {
                grecaptcha.execute(`${process.env.RECAPTCHA_KEY}`, { action: 'submit' }).then(function (token) {
                    $.ajax({
                        url: `${process.env.CONTACT_US_API_URL}`,
                        type: 'POST',
                        dataType: 'json',
                        contentType: "application/json",
                        data: JSON.stringify({
                            "company": result.company,
                            "email": result.email,
                            "message": result.message,
                            "name": result.name,
                            "phone": result.phone,
                            "rechaptchaCode": token,
                            "subject": result.select
                        }),
                        success: function (data, status) {

                            if (status === "success") {
                                toastr["success"]("Your message has been sent, thank you. Our team will get back to you as soon as possible.")
                            } else {
                                toastr["error"]("Authentication error. Please try again.")
                            }
                        },
                        error: function (xhr, status, error) {
                            console.log(xhr.responseText, status, error);
                        }
                    });

                });
            });
            $("#emailform").trigger("reset");
        }
    });
}




const validateForm = () => {

    let fields = $("#emailform .form-control")
    fields.each(function () {
        $(this).removeClass("has-error");
        $(this).next().css("display", "none");
        $("#errors2").css("display", "none");


        if ($(this).attr("id") === "name") {
            $(this).removeClass("has-error");
            $(this).next().next().css("display", "none");
        }

    });

    // Validate name
    let name = $("#name").val()
    let namePattern = /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$/;;
    if (name.trim().length < 3 || !namePattern.test(name)) {
        //alert("Name is required");
        $("#emailform #name").addClass("has-error");
        if (!namePattern.test(name)) {
            $("#emailform #name").addClass("has-error");
            $("#emailform #name").next().next().css("display", "block");
        }
        else {
            $("#emailform #name").addClass("has-error");
            $("#emailform #name").next().css("display", "block");
        }
        return false;
    }

    // Validate email address
    let email = $("#email").val()
    let emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
        //alert("Invalid email address");
        $("#emailform #email").addClass("has-error");
        $("#emailform #email").next().css("display", "block");
        return false;
    }

    // Valide phone

    let phone = $("#phone").val()
    let result =(phone.slice(phone.indexOf(")") + 1));

    if (result.length < 7 ||  result.length > 20) {
        $("#emailform #phone").addClass("has-error");
        $("#errors2").css("display", "block");
        return false
    }



    // Valide company   
    let company = $("#company").val()
    let companyPattern = /^[a-zA-ZğüşıöçĞÜŞİÖÇ\s\d.,-]*$/;
    if (company.trim().length < 3 || !companyPattern.test(company)) {
        // alert("company boş yada yanlış")
        $("#emailform #company").addClass("has-error");
        $("#emailform #company").next().css("display", "block");
        return false
    }
    //Valide Select
    let select = $("#subject").val()
    if (select == null) {
        //alert("select box boş")
        $("#emailform #subject").addClass("has-error");
        $("#emailform #subject").next().css("display", "block");
        return false
    }
    //Valide message
    let message = $("#message").val()
    if (!(message.trim().length > 5)) {
        //alert("mesaj çok kısa")
        $("#emailform #message").addClass("has-error");
        $("#emailform #message").next().css("display", "block");
        return false
    }
    let obj = { name, email, message, select, phone, company }
    return obj
}

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-full-width",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "linear",
    "hideEasing": "swing",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

export { contact }